import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';
import BigNumber from 'bignumber.js';
import { Rounding } from '@/sdk/constants';
import {
  PERCENTAGE_DECIMAL_PRECISION,
  USD_DECIMAL_PRECISION,
  USD_SIGN,
} from '@/constants/INTERFACE_SETTINGS';

const MAX_LENGTH_NUMBER_OF_BALANCE = 7;
const MIN_USD = 0.01;

export const format = formatNumbersMixin.methods.format;

export function toDecimalPlacesOrLT(
  value: string | number | BigNumber | undefined | null,
  options: { decimalPlaces?: number; addUsdSymbol?: boolean } = {},
): string {
  if (value == null) {
    return '';
  }

  const decimalPlaces = options.decimalPlaces || USD_DECIMAL_PRECISION;
  const symbolToInsert = options.addUsdSymbol ? USD_SIGN : '';

  const bnValue = new BigNumber(value);
  const limit = 10 ** -decimalPlaces;

  if (!bnValue.eq(0) && bnValue.lt(limit)) {
    return `< ${symbolToInsert}${limit}`;
  }

  return symbolToInsert + format(bnValue.decimalPlaces(decimalPlaces));
}

export function signedPercentFormat(value: string | number | BigNumber): string {
  if (value === null) {
    return '';
  }
  const bnValue = new BigNumber(value);
  if (bnValue.eq(0)) {
    return `${bnValue.toFixed(0)}%`;
  }
  const valueSign = bnValue.gt(0) ? '+' : '';
  return `${valueSign}${bnValue.toFixed(2, Rounding.ROUND_UP)}%`;
}

function formatWithMaxLength(value: BigNumber | string) {
  return format(value, MAX_LENGTH_NUMBER_OF_BALANCE);
}

function formatUSDWithMaxLength(value: BigNumber) {
  let amount = value.toString();
  if (value && (value.decimalPlaces() ?? 0) > USD_DECIMAL_PRECISION) {
    amount = value.toFixed(USD_DECIMAL_PRECISION, BigNumber.ROUND_DOWN);
  }

  return formatWithMaxLength(amount);
}

export function formatUSD(value: BigNumber | string | number) {
  return toDecimalPlacesOrLT(value, {
    decimalPlaces: USD_DECIMAL_PRECISION,
  });
}

export function formatLostInUSD(value: BigNumber) {
  const limit = 10 ** -USD_DECIMAL_PRECISION;

  if (!value.eq(0) && value.lt(limit)) {
    return `< ${limit}`;
  }

  return format(value.decimalPlaces(USD_DECIMAL_PRECISION, Rounding.ROUND_UP));
}

export function printUSDWithMaxLength(value: BigNumber) {
  if (value.lt(MIN_USD) && value.gt(0)) {
    return `< ${USD_SIGN}${MIN_USD}`;
  }
  return `${USD_SIGN}${formatUSDWithMaxLength(value)}`;
}

export function printUSDBalance(balance: BigNumber) {
  return printUSDWithMaxLength(balance);
}

export function printUSDBalanceWithCurrency(balance: BigNumber, currency: string) {
  if (balance.lt(MIN_USD) && balance.gt(0)) {
    return `< ${MIN_USD} ${currency}`;
  }
  return `${formatUSDWithMaxLength(balance)} ${currency}`;
}

export function printBalanceWithCurrency(balance: BigNumber, currency: string) {
  return `${formatWithMaxLength(balance)} ${currency}`;
}

export function printUSD(value: BigNumber | string | number) {
  return toDecimalPlacesOrLT(value, {
    decimalPlaces: USD_DECIMAL_PRECISION,
    addUsdSymbol: true,
  });
}

export function printUSDWithCurrency(value: BigNumber | string | number, currency: string) {
  return `${toDecimalPlacesOrLT(value, {
    decimalPlaces: USD_DECIMAL_PRECISION,
  })} ${currency}`;
}

export function printLostInUSDWithCurrency(value: BigNumber, currency: string) {
  return `${formatLostInUSD(value)} ${currency}`;
}

export function formatPercentage(value: BigNumber | string | number) {
  return toDecimalPlacesOrLT(value, {
    decimalPlaces: PERCENTAGE_DECIMAL_PRECISION,
  });
}
