import { ChainId } from '@/sdk/constants';
const DEFAULT_BALANCE = 0.02;

export function getMinGasAndBalanceByNetworkId(networkId: string | undefined): number {
  if (networkId === undefined) {
    return DEFAULT_BALANCE;
  }
  switch (+networkId) {
    case ChainId.ETH_SEPOLIA_TESTNET:
    case ChainId.ARBITRUM_TESTNET:
    case ChainId.BOB_CHAIN_TESTNET:
    case ChainId.BOB_CHAIN_MAINNET: {
      return 0.001;
    }
    case ChainId.MILKOMEDA_ALGORAND_TESTNET:
    case ChainId.MILKOMEDA_ALGORAND_MAINNET:
    case ChainId.KAVA_TESTNET:
    case ChainId.KAVA_MAINNET: {
      return 0.03;
    }
    case ChainId.BERA_CHAIN_TESTNET:
    case ChainId.POLYGON_TESTNET:
    case ChainId.POLYGON_MAINNET:
    case ChainId.MILKOMEDA_CARDANO_TESTNET:
    case ChainId.MILKOMEDA_CARDANO_MAINNET: {
      return DEFAULT_BALANCE;
    }
    default: {
      return DEFAULT_BALANCE;
    }
  }
}
